/*  =CSS Variables   
	============================================================================= */

:root {
    // Misc

    --border-radius-default: 4px;
    --border-radius-inputs: 4px;
    --border-radius-buttons: 4px;

    // Colors

    --color-accent-1: #2e2d4d;
    --color-accent-2: #d50747;
    --color-muted: #777;

    --color-text: #000000;
    --color-headings: #111111;

    --border-color: #ddd;
    --background-color: #f4f4f4;

    --color-form-elements: #000000;
    --color-form-placeholders: #94979e;
    --color-form-labels: #45464b;

    --background-color-form-elements: #ffffff;
    --background-color-form-elements-disabled: #f4f4f4;

    --border-color-form-elements: #ddd;
    --border-color-form-elements-focus: #ccc;

    --color-link: #2e2d4d;
    --color-link-hover: #d50747;
    --color-link-active: #d50747;

    // Typography

    --font-family-body: "Segoe UI", sans-serif;
    --font-weight-body: 400;
    --line-height-body: 1.765;
    --letter-spacing-body: 0;

    --font-size-body: 16px;

    --font-family-headings: "Acumin Pro", sans-serif;
    --font-weight-headings: 100;
    --line-height-headings: 1.3;
    --letter-spacing-headings: 0;

    --font-size-h1: 24px;
    --font-size-h2: 22px;
    --font-size-h3: 20px;
    --font-size-h4: 18px;
    --font-size-h5: 16px;
    --font-size-h6: 14px;

    --font-spacing-unit: calc(var(--font-size-body) * var(--line-height-body));

    --font-spacing-1: calc(var(--font-spacing-unit) / 8);
    --font-spacing-2: calc(var(--font-spacing-unit) / 4);
    --font-spacing-3: calc(var(--font-spacing-unit) / 2);
    --font-spacing-4: var(--font-spacing-unit);
    --font-spacing-5: calc(var(--font-spacing-unit) * 1.5);
    --font-spacing-6: calc(var(--font-spacing-unit) * 2);
    --font-spacing-7: calc(var(--font-spacing-unit) * 2.5);
    --font-spacing-8: calc(var(--font-spacing-unit) * 3);

    --gradient-start: #c2cab9;
    --gradient-end: #bbd8ce;
}
