/*! 
 * Archea Custom Icon Font - Forked from Feather Icon Pack by Cole Bemis (https://feathericons.com/) - Licensed under MIT 
 */
@font-face {
  font-family: 'archeaiconfont';
  src:
    url('../fonts/archeaiconfont/archeaiconfont.woff2?v8km37') format('woff2'),
    url('../fonts/archeaiconfont/archeaiconfont.ttf?v8km37') format('truetype'),
    url('../fonts/archeaiconfont/archeaiconfont.woff?v8km37') format('woff'),
    url('../fonts/archeaiconfont/archeaiconfont.svg?v8km37#archeaiconfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'archeaiconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.archea-icon-activity:before {
  content: "\e900";
}
.archea-icon-alert-circle:before {
  content: "\e901";
}
.archea-icon-alert-triangle:before {
  content: "\e902";
}
.archea-icon-align-center:before {
  content: "\e903";
}
.archea-icon-align-justify:before {
  content: "\e904";
}
.archea-icon-align-left:before {
  content: "\e905";
}
.archea-icon-align-right:before {
  content: "\e906";
}
.archea-icon-arrow-down:before {
  content: "\e907";
}
.archea-icon-arrow-down-circle:before {
  content: "\e908";
}
.archea-icon-arrow-down-left:before {
  content: "\e909";
}
.archea-icon-arrow-down-right:before {
  content: "\e90a";
}
.archea-icon-arrow-left:before {
  content: "\e90b";
}
.archea-icon-arrow-left-circle:before {
  content: "\e90c";
}
.archea-icon-arrow-right:before {
  content: "\e90d";
}
.archea-icon-arrow-right-circle:before {
  content: "\e90e";
}
.archea-icon-arrow-up:before {
  content: "\e90f";
}
.archea-icon-arrow-up-circle:before {
  content: "\e910";
}
.archea-icon-arrow-up-left:before {
  content: "\e911";
}
.archea-icon-arrow-up-right:before {
  content: "\e912";
}
.archea-icon-book:before {
  content: "\e913";
}
.archea-icon-book-open:before {
  content: "\e914";
}
.archea-icon-bookmark:before {
  content: "\e915";
}
.archea-icon-box:before {
  content: "\e916";
}
.archea-icon-briefcase:before {
  content: "\e917";
}
.archea-icon-calendar:before {
  content: "\e918";
}
.archea-icon-check:before {
  content: "\e919";
}
.archea-icon-check-circle:before {
  content: "\e91a";
}
.archea-icon-check-square:before {
  content: "\e91b";
}
.archea-icon-chevron-down:before {
  content: "\e91c";
}
.archea-icon-chevron-left:before {
  content: "\e91d";
}
.archea-icon-chevron-right:before {
  content: "\e91e";
}
.archea-icon-chevron-up:before {
  content: "\e91f";
}
.archea-icon-chevrons-down:before {
  content: "\e920";
}
.archea-icon-chevrons-left:before {
  content: "\e921";
}
.archea-icon-chevrons-right:before {
  content: "\e922";
}
.archea-icon-chevrons-up:before {
  content: "\e923";
}
.archea-icon-clipboard:before {
  content: "\e924";
}
.archea-icon-clock:before {
  content: "\e925";
}
.archea-icon-code:before {
  content: "\e926";
}
.archea-icon-compass:before {
  content: "\e927";
}
.archea-icon-corner-down-left:before {
  content: "\e928";
}
.archea-icon-corner-down-right:before {
  content: "\e929";
}
.archea-icon-corner-left-down:before {
  content: "\e92a";
}
.archea-icon-corner-left-up:before {
  content: "\e92b";
}
.archea-icon-corner-right-down:before {
  content: "\e92c";
}
.archea-icon-corner-right-up:before {
  content: "\e92d";
}
.archea-icon-corner-up-left:before {
  content: "\e92e";
}
.archea-icon-corner-up-right:before {
  content: "\e92f";
}
.archea-icon-credit-card:before {
  content: "\e930";
}
.archea-icon-crop:before {
  content: "\e931";
}
.archea-icon-crosshair:before {
  content: "\e932";
}
.archea-icon-database:before {
  content: "\e933";
}
.archea-icon-droplet:before {
  content: "\e934";
}
.archea-icon-edit:before {
  content: "\e935";
}
.archea-icon-edit-2:before {
  content: "\e936";
}
.archea-icon-edit-3:before {
  content: "\e937";
}
.archea-icon-external-link:before {
  content: "\e938";
}
.archea-icon-eye:before {
  content: "\e939";
}
.archea-icon-eye-off:before {
  content: "\e93a";
}
.archea-icon-facebook:before {
  content: "\e93b";
}
.archea-icon-fast-forward:before {
  content: "\e93c";
}
.archea-icon-feather:before {
  content: "\e93d";
}
.archea-icon-file:before {
  content: "\e93e";
}
.archea-icon-file-minus:before {
  content: "\e93f";
}
.archea-icon-file-plus:before {
  content: "\e940";
}
.archea-icon-file-text:before {
  content: "\e941";
}
.archea-icon-filter:before {
  content: "\e942";
}
.archea-icon-folder:before {
  content: "\e943";
}
.archea-icon-folder-minus:before {
  content: "\e944";
}
.archea-icon-folder-plus:before {
  content: "\e945";
}
.archea-icon-globe:before {
  content: "\e946";
}
.archea-icon-grid:before {
  content: "\e947";
}
.archea-icon-hard-drive:before {
  content: "\e948";
}
.archea-icon-headphones:before {
  content: "\e949";
}
.archea-icon-heart:before {
  content: "\e94a";
}
.archea-icon-help-circle:before {
  content: "\e94b";
}
.archea-icon-hexagon:before {
  content: "\e94c";
}
.archea-icon-home:before {
  content: "\e94d";
}
.archea-icon-image:before {
  content: "\e94e";
}
.archea-icon-inbox:before {
  content: "\e94f";
}
.archea-icon-info:before {
  content: "\e950";
}
.archea-icon-instagram:before {
  content: "\e951";
}
.archea-icon-layers:before {
  content: "\e952";
}
.archea-icon-life-buoy:before {
  content: "\e953";
}
.archea-icon-link:before {
  content: "\e954";
}
.archea-icon-link-2:before {
  content: "\e955";
}
.archea-icon-list:before {
  content: "\e956";
}
.archea-icon-lock:before {
  content: "\e957";
}
.archea-icon-log-in:before {
  content: "\e958";
}
.archea-icon-log-out:before {
  content: "\e959";
}
.archea-icon-mail:before {
  content: "\e95a";
}
.archea-icon-map:before {
  content: "\e95b";
}
.archea-icon-map-pin:before {
  content: "\e95c";
}
.archea-icon-maximize:before {
  content: "\e95d";
}
.archea-icon-maximize-2:before {
  content: "\e95e";
}
.archea-icon-menu:before {
  content: "\e95f";
}
.archea-icon-message-circle:before {
  content: "\e960";
}
.archea-icon-message-square:before {
  content: "\e961";
}
.archea-icon-minimize:before {
  content: "\e962";
}
.archea-icon-minimize-2:before {
  content: "\e963";
}
.archea-icon-minus:before {
  content: "\e964";
}
.archea-icon-minus-circle:before {
  content: "\e965";
}
.archea-icon-minus-square:before {
  content: "\e966";
}
.archea-icon-monitor:before {
  content: "\e967";
}
.archea-icon-moon:before {
  content: "\e968";
}
.archea-icon-more-horizontal:before {
  content: "\e969";
}
.archea-icon-more-vertical:before {
  content: "\e96a";
}
.archea-icon-mouse-pointer:before {
  content: "\e96b";
}
.archea-icon-move:before {
  content: "\e96c";
}
.archea-icon-navigation:before {
  content: "\e96d";
}
.archea-icon-navigation-2:before {
  content: "\e96e";
}
.archea-icon-paperclip:before {
  content: "\e96f";
}
.archea-icon-plus:before {
  content: "\e970";
}
.archea-icon-power:before {
  content: "\e971";
}
.archea-icon-printer:before {
  content: "\e972";
}
.archea-icon-refresh-ccw:before {
  content: "\e973";
}
.archea-icon-refresh-cw:before {
  content: "\e974";
}
.archea-icon-repeat:before {
  content: "\e975";
}
.archea-icon-save:before {
  content: "\e976";
}
.archea-icon-search:before {
  content: "\e977";
}
.archea-icon-send:before {
  content: "\e978";
}
.archea-icon-server:before {
  content: "\e979";
}
.archea-icon-settings:before {
  content: "\e97a";
}
.archea-icon-share:before {
  content: "\e97b";
}
.archea-icon-shield:before {
  content: "\e97c";
}
.archea-icon-shopping-cart:before {
  content: "\e97d";
}
.archea-icon-shuffle:before {
  content: "\e97e";
}
.archea-icon-sidebar:before {
  content: "\e97f";
}
.archea-icon-skip-back:before {
  content: "\e980";
}
.archea-icon-skip-forward:before {
  content: "\e981";
}
.archea-icon-sliders:before {
  content: "\e982";
}
.archea-icon-smartphone:before {
  content: "\e983";
}
.archea-icon-smile:before {
  content: "\e984";
}
.archea-icon-sun:before {
  content: "\e985";
}
.archea-icon-tablet:before {
  content: "\e986";
}
.archea-icon-tag:before {
  content: "\e987";
}
.archea-icon-terminal:before {
  content: "\e988";
}
.archea-icon-thumbs-down:before {
  content: "\e989";
}
.archea-icon-thumbs-up:before {
  content: "\e98a";
}
.archea-icon-tool:before {
  content: "\e98b";
}
.archea-icon-trash:before {
  content: "\e98c";
}
.archea-icon-trending-down:before {
  content: "\e98d";
}
.archea-icon-trending-up:before {
  content: "\e98e";
}
.archea-icon-twitter:before {
  content: "\e98f";
}
.archea-icon-unlock:before {
  content: "\e990";
}
.archea-icon-user:before {
  content: "\e991";
}
.archea-icon-users:before {
  content: "\e992";
}
.archea-icon-video:before {
  content: "\e993";
}
.archea-icon-volume-2:before {
  content: "\e994";
}
.archea-icon-x:before {
  content: "\e995";
}
.archea-icon-x-square:before {
  content: "\e996";
}
.archea-icon-youtube:before {
  content: "\e997";
}
.archea-icon-zap:before {
  content: "\e998";
}
.archea-icon-zoom-in:before {
  content: "\e999";
}
.archea-icon-zoom-out:before {
  content: "\e99a";
}
