/* ==========================================================================
   =Wrap
   ========================================================================== */

#wrap {
}

/* ==========================================================================
   =Header
   ========================================================================== */

#header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e9e9e9;
    padding: 15px 0;
}

/* =Logo
   ========================================================================== */

#logo {
}

#logo img {
    height: 25px;
}

/* ==========================================================================
   =Secondary navigation
   ========================================================================== */

#secondary-navigation {
    margin-bottom: 25px !important;
    padding: 15px 0;
    margin-bottom: 0;
    border-bottom: 1px solid #cecfcf;
    &.secondary-navigation--alt {
        margin-bottom: 0 !important;
    }
}

/* 1. When the secondary navigation becomes sticky */

.header-sticky {
    top: 0;
    z-index: 1000;
    background-color: #f9fbfa;
    border-bottom: 1px solid #ddd;
}

.secondary-navigation-sticky {
    /*1*/
    top: 73px;
    z-index: 1000;
    background-color: #f9fbfa;
}

#secondary-navigation h1 {
    margin-bottom: 15px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 300;
}

#secondary-navigation ul {
    list-style: none;
    margin-bottom: 0;
    display: flex;
    gap: 15px;
}

#secondary-navigation ul li {
}

#secondary-navigation ul li a {
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    color: #3b3b3b;
}

/* 3. scroll spy effect */

#secondary-navigation ul li.active a {
    /*3*/
    font-weight: 700;
    color: #2b2b2b;
}

@media (max-width: 768px) {
    /* 2. Hide page title in sticky navigation */

    .secondary-navigation-sticky h1 {
        /*2*/
        display: none;
    }

    /* 4. Add vertical scroll on mobile devices to secondary navigation to make sure all elements are accesible */

    #secondary-navigation ul {
        /* 4 */
        overflow: hidden;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

@media (min-width: 992px) {
    #secondary-navigation h1 {
        margin-bottom: 0;
    }

    #secondary-navigation ul {
        justify-content: flex-end;
    }
}

/* ==========================================================================
   =Impact > Expertise
   ========================================================================== */

.mb-25 {
    margin-bottom: 25px;
}
.mb-50 {
    margin-bottom: 50px;
}

#expertise {
    padding-top: 40px;
}

#expertise img {
    margin: 0 0 25px 0;
}

#expertise ul {
    list-style: none;
    margin-bottom: 0;
}

#expertise ul li {
    position: relative;
    padding-left: 45px;
}

#expertise ul li h3 {
    margin-bottom: 15px;
}

#expertise ul li span {
    border: 1px dashed #bbbcbc;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-weight: 400;
    background-color: #ffffff;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
}

@media (min-width: 992px) {
    #expertise img {
        margin: 0 0 30px 0;
    }
}

/* ==========================================================================
   =Impact > Partnerships
   ========================================================================== */

#partnerships #industries img {
    width: 100px;
    margin-bottom: 0;
}

#partnerships {
    // background-color: #efefef;
    // padding: 50px 0;
}

@media (min-width: 1200px) {
    #partnerships #industries img {
        width: 250px;
    }
}

/* ==========================================================================
   =Impact > Support Us
   ========================================================================== */

#support-us {
    // padding: 55px 0 40px;
}

#support-us img {
    margin: 0 0 30px 0;
}

#support-us ul {
    list-style: none;
}

#support-us ul li:not(:last-child) {
    margin-bottom: 15px;
}
