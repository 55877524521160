/* ==========================================================================
   =Typography
   ========================================================================== */

@font-face {
    font-family: "Acumin Concept";
    src: url("../fonts/acumin-pro/acuminvariableconcept-wideextralight-webfont.woff2")
            format("woff2"),
        url("../fonts/acumin-pro/acuminvariableconcept-wideextralight-webfont.woff")
            format("woff");
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: "Acumin Pro";
//     src: url("../fonts/acumin-pro/AcuminPro-Italic.woff2") format("woff2"),
//         url("../fonts/acumin-pro/AcuminPro-Italic.woff") format("woff");
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Acumin Pro";
//     src: url("../fonts/acumin-pro/AcuminPro-Regular.woff2") format("woff2"),
//         url("../fonts/acumin-pro/AcuminPro-Regular.woff") format("woff");
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Acumin Concept";
//     src: url("../fonts/acumin-pro/AcuminPro-LightItalic.woff2") format("woff2"),
//         url("../fonts/acumin-pro/AcuminPro-LightItalic.woff") format("woff");
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Acumin Concept";
//     src: url("../fonts/acumin-pro/AcuminPro-ThinItalic.woff2") format("woff2"),
//         url("../fonts/acumin-pro/AcuminPro-ThinItalic.woff") format("woff");
//     font-weight: 100;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Acumin Concept";
//     src: url("../fonts/acumin-pro/AcuminPro-ExtraLight.woff2") format("woff2"),
//         url("../fonts/acumin-pro/AcuminPro-ExtraLight.woff") format("woff");
//     font-weight: 200;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Acumin Concept";
//     src: url("../fonts/acumin-pro/AcuminPro-Light.woff2") format("woff2"),
//         url("../fonts/acumin-pro/AcuminPro-Light.woff") format("woff");
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Acumin Pro";
//     src: url("../fonts/acumin-pro/AcuminPro-ExtraLightItalic.woff2")
//             format("woff2"),
//         url("../fonts/acumin-pro/AcuminPro-ExtraLightItalic.woff")
//             format("woff");
//     font-weight: 200;
//     font-style: italic;
//     font-display: swap;
// }

@font-face {
    font-family: "Acumin Concept";
    src: url("../fonts/acumin-pro/AcuminPro-Thin.woff2") format("woff2"),
        url("../fonts/acumin-pro/AcuminPro-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Segoe UI";
    src: url("../fonts/segoe-ui/SegoeUI.eot");
    src: local("Segoe UI"), local("SegoeUI"),
        url("../fonts/segoe-ui/SegoeUI.eot?#iefix") format("embedded-opentype"),
        url("../fonts/segoe-ui/SegoeUI.woff2") format("woff2"),
        url("../fonts/segoe-ui/SegoeUI.woff") format("woff"),
        url("../fonts/segoe-ui/SegoeUI.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Segoe UI";
    src: url("../fonts/segoe-ui/SegoeUI-SemiBold.eot");
    src: local("Segoe UI Semibold"), local("SegoeUI-SemiBold"),
        url("../fonts/segoe-ui/SegoeUI-SemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/segoe-ui/SegoeUI-SemiBold.woff2") format("woff2"),
        url("../fonts/segoe-ui/SegoeUI-SemiBold.woff") format("woff"),
        url("../fonts/segoe-ui/SegoeUI-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Segoe UI";
    src: url("../fonts/segoe-ui/SegoeUI-Light.eot");
    src: local("Segoe UI Light"), local("SegoeUI-Light"),
        url("../fonts/segoe-ui/SegoeUI-Light.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/segoe-ui/SegoeUI-Light.woff2") format("woff2"),
        url("../fonts/segoe-ui/SegoeUI-Light.woff") format("woff"),
        url("../fonts/segoe-ui/SegoeUI-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Segoe UI";
    src: url("../fonts/segoe-ui/SegoeUI-Bold.eot");
    src: local("Segoe UI Bold"), local("SegoeUI-Bold"),
        url("../fonts/segoe-ui/SegoeUI-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/segoe-ui/SegoeUI-Bold.woff2") format("woff2"),
        url("../fonts/segoe-ui/SegoeUI-Bold.woff") format("woff"),
        url("../fonts/segoe-ui/SegoeUI-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Segoe UI";
    src: url("../fonts/segoe-ui/SegoeUI-Italic.eot");
    src: local("Segoe UI Italic"), local("SegoeUI-Italic"),
        url("../fonts/segoe-ui/SegoeUI-Italic.eot?#iefix")
            format("embedded-opentype"),
        url("../fonts/segoe-ui/SegoeUI-Italic.woff2") format("woff2"),
        url("../fonts/segoe-ui/SegoeUI-Italic.woff") format("woff"),
        url("../fonts/segoe-ui/SegoeUI-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

*,
html {
    // scroll-behavior: smooth !important;
    // scroll-margin-top: 55px;
}

body {
    background-color: #f9fbfa;
    color: #2b2b2b;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0;
    @media (min-width: 768px) {
        font-size: 20px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2b2b2b;
    font-family: "Acumin Concept", sans-serif;
    line-height: 1.3;
    letter-spacing: 0;
    margin: 25px 0;
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 22px;
    @media (min-width: 768px) {
        font-size: 38px;
    }
    @media (min-width: 1200px) {
        font-size: 40px;
    }
}

h3 {
    font-size: 20px;
    @media (min-width: 768px) {
        font-size: 24px;
    }
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
    margin-top: 0 !important;
}

p {
    margin-bottom: 25px;
}

strong {
    font-weight: bolder;
}

em {
    font-style: italic;
}

small {
    font-size: 80%;
}

big {
    font-size: 120%;
}

sub {
    font-size: 65%;
    vertical-align: sub;
}

sup {
    font-size: 65%;
    vertical-align: super;
}

abbr[title] {
    border-bottom: 1px dotted #2b2b2b;
    cursor: help;
}

mark,
ins {
    text-decoration: none;
}

s,
del {
    text-decoration: line-through;
}

/* ==========================================================================
   =Forms
   ========================================================================== */

/* ==========================================================================
   =Formatting
   ========================================================================== */

hr {
    height: 1px;
    border: 0;
    margin: 28px 0;
    background-color: #e5e7e6;
}

/* ==========================================================================
   =Lists
   ========================================================================== */

ul,
ol {
    margin-bottom: 28px;
    list-style-position: inside;
    padding-left: 0;
}

ul {
    list-style-type: disc;
}
ol {
    list-style-type: decimal;
}

li {
}

li > ul,
li > ol {
    margin-bottom: 0;
    margin-left: 28px;
}

dl {
    margin-bottom: 28px;
}

dt {
}

dd {
    margin-bottom: 28px;
}

/* ==========================================================================
   =Images
   ========================================================================== */

img {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: middle;
}

/* ==========================================================================
   =Tables
   ========================================================================== */

table,
th,
td {
}

table {
    width: 100%;
    border: 1px solid #e5e7e6;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 4px;
    margin-bottom: 28px;
    table-layout: auto;
}

caption {
    margin-bottom: 28px;
    text-align: left;
}

th {
    padding: 14px;
    border: 1px solid #e5e7e6;
    text-align: left;
}

td {
    padding: 14px;
    border: 1px solid #e5e7e6;
    text-align: left;
}

/* ==========================================================================
   =Links
   ========================================================================== */

a {
    color: #2b2b2b;
    text-decoration: none;
    outline: 0;
    transition: color 0.25s;
}

a:focus {
    color: #2b2b2b;
}
a:hover {
    color: #2b2b2b;
}
a:active {
    color: #2b2b2b;
}

/* ==========================================================================
   =Gutter
   ========================================================================== */

.gx-lg-c {
    padding: 0 1rem;
    @media (min-width: 768px) {
        padding: 0 6rem;
    }
}
