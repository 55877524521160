// Logo
.header {
    &__logo {
        display: flex;
        align-items: center;
        svg {
            display: block;
            width: 200px;
            transition: all 200ms linear;
            z-index: 9999;
        }
    }
}

// video background
iframe {
    transition: opacity 500ms ease-in-out;
    transition-delay: 250ms;
}

@media (min-width: 1600px) {
    .container-xxl {
        max-width: 1820px;
    }
}

// homepage

.background-home {
    position: inherit;
    display: flex;
    align-items: flex-end;
    @media (min-width: 768px) {
        align-items: center;
    }
}

.p-custom {
    padding: 0 15px;
}

.custom__nav {
    border-bottom: 1px solid #cecfcf;
    border-top: 1px solid #cecfcf;
    margin-bottom: 25px;
    ul {
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        li {
            list-style-type: none;
            flex-basis: 33%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 12px 0;
            &:last-child {
                a {
                    border-right: 0;
                }
            }

            a {
                display: block;
                width: 100%;
                text-transform: uppercase;
                font-weight: normal;
                font-size: 18px;
                text-align: center;
                letter-spacing: 1px;
                transition: all 200ms linear;
                border-right: 1px solid #cecfcf;
                &:hover {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }
}

// Meniu
header {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
}

header .menu-btn {
    cursor: pointer;
    width: 35px;
}

nav.general {
    position: fixed;
    background: black;
    transition: all 200ms ease;
    max-width: 0px;
    right: 0;
    height: 100vh;
    overflow: hidden;
    z-index: 9999;

    &.show-menu {
        display: block;
        max-width: 100%;
        width: 100%;
    }

    > ul {
        @media (min-width: 768px) {
            padding: 10vw;
        }
    }

    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 32px;
        height: 32px;
    }
}

// Globe

.mobile-video {
    height: 70vh;
    width: 100vw;
    overflow: hidden;
}

#background-video,
#background-video-desktop {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}

.mobile-video-heading {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 300px;
    text-align: center;
    font-size: 30px;

    h2 {
        font-size: inherit;
        margin-bottom: 0;
    }
}

.backgound-desktop-home {
    position: inherit;
    display: flex;
    align-items: flex-end;
    @media (min-width: 768px) {
        align-items: center;
    }

    .desktop-video {
        height: 90vh;
        // width: 100vw;
        overflow: hidden;
    }

    .bg-section-home {
        // position: absolute;
        // left: 0;
        // right: 0;
        // bottom: 0px;
        margin-top: -80px;
        @media (min-width: 768px) {
            margin-top: 0px;
            max-width: 26vw;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20vw;
        }
        h2 {
            margin: 0;
            padding: 0;
            text-align: center;
            z-index: 100;
            position: relative;
            font-size: 30px;
            width: 100%;
            font-weight: 100;

            @media (min-width: 768px) {
                text-align: left;
                margin: 25px 0;
                font-size: 52px;
            }
        }
        p {
            padding: 10px;
            margin-bottom: 0;
            // color: #fff;
        }
    }
}

.mobile-video-heading {
    h2 {
        font-weight: 100;
    }
}

// Project page

#projects-overview {
    background-image: url("./../images/projects/adaptable-residences/Archea-Projects-9.jpg");
    display: flex;
    background-size: cover;
    align-items: center;
    justify-content: center;
    video {
        width: 100%;
    }
}
